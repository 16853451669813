/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

// $theme-colors: (
//   'info': teal,
//   'danger':tomato
// );

// $primary: #204ecf;
// $secondary: #262d3d;

/* import google fonts here */
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
  color: var(--blackColor);
}

:root {
  --sidebarColor: #636b78;
  --sidebarClientColor: #8bc7cb;
  --pageBgColor: #ff5f0000;
  --grayHeadingColor: #a5a5a5;
  --lightGrayColor: #f2f2f2;
  --grayColor: #cccccc;
  --darkGrayColor: #828282;
  --whiteColor: #ffffff;
  --blackColor: #333333;
  --darkColor: #000000;
  --tealColor: #00b0ba;
  --orangeColor: #d76a33;
  --lightOrangeColor: #efdad1;
  --activeGreenColor: #4bb543;
  --activeGreenLightColor: #4bb54317;
  --headerColor: #eaebec;
  --bodyColor: #ffffff;
  --gray150: #e6e6e6;
  --lighterOrangeColor: #feefe7;
  --lightTealColor: #e6feff;
  --lighterTealColor: #e6feff40;
  --lightSidebar: #636b7878;
  --darkTealColor: #028183;
  --errorColor: #d00909;
  --processingColor: #0000a0;
  --pendingColor: #d62b2b;
  --pendingReviewColor: #6cc3df;
  --pendingApprovalColor: #dcbf24;
  --approvedColor: #008000;
  --archiveColor: #f6b67d;
  --rejectColor: #ff0000;
  --assignColor: #00b8c2;
  --activeTabsColor: #f5d7c3;
  --inputFocus: #96f2f640;
  --selectGroupLabelColor: #212529a6;
}

.astrick {
  color: var(--orangeColor) !important;
}

.consent-area {
  label {
    font-size: 1rem;
  }
  .disc {
    font-size: 0.854rem;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
  }
  input[type="checkbox"] {
    position: relative;
    border: 1px solid var(--blackColor);
    border-radius: 4px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    &:before {
      content: "";
      position: absolute;
      right: 50%;
      top: 50%;
      width: 5px;
      height: 10px;
      border: solid transparent;
      border-width: 0 2px 2px 0;
      margin: -1px -1px 0 -1px;
      transform: rotate(45deg) translate(-50%, -50%);
      z-index: 2;
    }
    &:checked {
      background-color: var(--tealColor);
      opacity: 1;
      border: 1px solid var(--tealColor);
      border-radius: 4px;
      &:before {
        border-color: var(--whiteColor);
      }
    }
    @media (max-width: 580px){
      width: 15px;
      height: 15px;
    }
  }
}

// custom table

.common-table-client {
  outline: none !important;
}

.common-table {
  outline: 1px solid var(--lightGrayColor);
  border-radius: 22px 0 0 22px;
  max-height: 60vh;
  table {
    margin-bottom: 0;
    width: 100%;
    thead {
      width: 100%;
      height: 55px;
      position: sticky;
      top: 0;
      tr {
        border-bottom: 1px solid var(--lightGrayColor);
        vertical-align: middle;
        th {
          background-color: var(--tealColor);
          color: var(--whiteColor);
          font-weight: 600;
          font-size: 0.964rem;
          line-break: auto;
          &:first-child {
            padding-left: 1rem;
            width: 6rem;
          }
          &:nth-child(2) {
            width: 13rem;
          }
        }
      }
    }

    tbody {
      tr {
        width: 100%;
        border-bottom: 1px solid var(--lightTealColor);
        height: 50px;
        vertical-align: middle;
        &:last-child {
          border-color: var(--whiteColor);
        }
        td {
          font-size: 0.864rem;
          .arrowToggle {
            width: 10px;
            transition: transform 0.5s ease;
            &.rotated {
              transform: rotate(180deg);
            }
          }
          &:first-child {
            width: 6rem;
            padding-left: 1rem;
          }
          &:nth-child(2) {
            width: 13rem;
          }
          .actionButton{
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
        .circle {
          width: 10px;
          height: 9px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 8px;
        }
        .red {
          background-color: var(--rejectColor);
        }
        .green {
          background-color: var(--approvedColor);
        }
        .yellow {
          background-color: var(--pendingApprovalColor);
        }

        &.parentTableLi {
          &.activeCol {
            border-bottom: 1px solid var(--tealColor);
          }
        }
        &.last-inner-table {
          border-bottom: 1px solid var(--tealColor);
        }
      }
    }
  }
}

// custom table

// common tabs

.tabs {
  .tab {
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 0px 3px 0px var(--grayHeadingColor);
    width: 16%;
    @media (max-width: 580px){
      width: 100%;
    }
  }
  .active {
    background-color: var(--activeTabsColor);
    font-weight: 500;
    border: 1px solid var(--activeTabsColor);
  }
}

.flex-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-footer {
  border-top: none;
}

.form-floating {
  z-index: 0;
  input,
  select,
  textarea {
    border: 0;
    border-bottom: 1px solid var(--grayColor);
    border-radius: 0;
    padding-left: 5px !important;
    &.inactive-input{
      color: transparent;
      text-shadow: 0 0 0 var(--darkGrayColor);
    }
    &:focus {
      box-shadow: none;
    }
    &:focus::placeholder {
      color: var(--grayHeadingColor);
    }
    &.inactive-input:focus{
      outline: none;
    }
  }

  label span {
    padding-left: 0;
    margin-bottom: 0px;
    font-size: 0.954rem;
    color: var(--grayHeadingColor);
    img {
      margin-left: 2px;
    }
  }
}
.form-floating > label {
  padding: 1rem 0rem !important;
  // pointer-events: auto;
}

.tooltipIconPointer {
  pointer-events: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.arrowIcon {
  transition: transform 0.5s ease;
  cursor: pointer;
}

.arrowIcon.icon-rotated {
  transform: rotate(180deg);
}

.dropdown-item.active {
  background-color: var(--tealColor) !important;
}
.dropdown-item:active {
  background-color: var(--lightGrayColor) !important;
  color: var(--blackColor);
}
.form-control:focus {
  border-color: var(--tealColor);
  box-shadow: 0 0 0 0.25rem var(--inputFocus);
}

::-webkit-scrollbar {
  width: 6px;
  @media (max-width: 991px) {
    width: 4px;
  }
}

::-webkit-scrollbar:horizontal {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--grayColor);
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: -webkit-linear-gradient(
    120deg,
    var(--darkTealColor),
    var(--lightOrangeColor)
  );
  background: linear-gradient(
    -60deg,
    var(--orangeColor),
    var(--darkTealColor) 40%
  );
  border-radius: 3px;
}
.input-with-add {
  background-color: var(--tealColor);
  border: 0;
  border-bottom: 1px solid var(--grayColor);
  justify-content: center;
  cursor: pointer;
  border-radius: 999em;
  width: 80px;
  text-align: center;
  color: var(--whiteColor);
}

.sm-label {
  padding-left: 2;
  margin-bottom: 0px;
  font-size: 0.854rem;
  // color: var(--grayColor);S
}

.dotted-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.center-blank-page {
  width: 100%;
  height: 60vh;
  align-items: center;
  justify-content: center;
}

.text-avtar-container {
  width: 100%;
  background-color: var(--headerColor);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid var(--lightGrayColor);
  span {
    font-size: 3rem;
    color: var(--grayColor);
  }
}

.active-text-color {
  color: var(--tealColor);
}
.comment-section {
  max-height: 400px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
  .client-name {
    font-weight: 500;
  }
}
.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  span {
    color: var(--darkGrayColor);
    background-color: transparent;
    position: absolute;
    // left: 50%;
    font-size: 16px;
  }
}
.view-btn {
  text-decoration: none;
  color: var(--whiteColor) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 25px;
  font-weight: 600;
  padding: 2px 10px;
  line-height: 1rem;
  line-break: strict;
}
.span-btn {
  text-decoration: none;
  color: var(--whiteColor) !important;
  border-radius: 25px;
  font-weight: 600;
  padding: 3px 15px;
  text-align: center;
}
.icon-btn {
  text-decoration: none;
  color: var(--whiteColor) !important;
  border-radius: 25px;
  font-weight: 600;
  padding: 3px 15px;
  text-align: center;
}
.assign-chip {
  background: var(--assignColor);
}
.pending-chip {
  background: var(--pendingColor);
}
.process-chip {
  background: var(--processingColor);
}
.pending-review-chip {
  background: var(--pendingReviewColor);
}
.pending-approval-chip {
  background: var(--pendingApprovalColor);
}
.approval-chip {
  background: var(--approvedColor);
}
.archive-chip {
  background: var(--archiveColor);
}
.rejected-chip {
  background: var(--rejectColor);
}
.published-chip {
  background: var(--tealColor);
}
.contentType-chip {
  background: var(--archiveColor);
}
.loaderOnImg {
  height: 30vh;
}

.displayNone {
  display: none;
}
.croperImageContainer{
  max-height: 75vh;
  overflow-x: auto;
}
.importContainer{
  max-height: 65vh;
  overflow-x: auto;
}
.status-txt {
  line-break: strict;
  text-align: center;
}

.dashboard-heading{
  font-family: 'Kanit', sans-serif;
}
