.press_release_link{
    padding: 50px 0;
    .left{
        // box-shadow: 0 0px 5px #e1e1e1;
        padding: 15px;
        border-radius: 15px;
        // border: 1px solid #efefef;
        & > * {
            margin-bottom: 15px;
            display: block;
        }
        img {
            border-radius: 10px;
            width: 100%;
        }

        i {
            color: #a9a9a9;
        }

        h1{
            font-size: 2rem;
            font-weight: 600;
            text-transform: capitalize;
        }
        p{
            word-break: break-word;
        }
        .tags {
            display: flex !important;
            align-items: center;
            gap: 1rem;
            flex-wrap: wrap;
            h4{
                text-transform: uppercase;
                margin-bottom: 0;
                font-size: 1.254rem;
                font-weight: 600;
            }
            & > a{
                border: 1px solid #e1e1e1;
                padding: 5px;
                color: inherit;
                text-decoration: none;
                background-color: #fbfbfb;
                text-transform: capitalize;
            }
        }
    }
    .right{
        position: sticky;
        top: 70px;
        .commented-area{
            display: flex;
            // display: grid;
            // grid-template-columns: auto auto;
            gap: 1rem;
            border-bottom: 1px solid #efefef;
            margin-bottom: 0.5rem;
            /* box-shadow: 0 0 5px #dfdfdf; */
            padding: 10px 0px;
            img{
                width: 40px;
                height: 40px;
                aspect-ratio: 1/1;
                object-fit: contain;
            }
            .detail{
                h5{
                    span{
                        font-size: 0.864rem;
                        margin-left: 5px;
                        color: #b5b5b5;
                    }
                }
                p{
                    font-size: 0.864rem;
                    line-height: 1.2rem;
                }
            }
        }
    }
}
.blurred-text-shadow {
    font-size: 40px;
    color: transparent;
    text-shadow:   
    0 0 10px rgba(0, 0, 0, 0.08),
    0 0 20px rgba(0, 0, 0, 0.07),
    0 0 30px rgba(0, 0, 0, 0.07),
    0 0 40px rgba(0, 0, 0, 0.07);
    pointer-events: none;
    user-select: none;
}
.readMoreButton{
    position: absolute;
    font-size: 20px;
    font-weight: 500;
    left:50%;
    transform: translateX(-50%)
}
footer {
    background-color: #2c2c2c;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
        color: var(--whiteColor);
    }
}
.userProfileImg{
    border: 1px solid var(--lightGrayColor);
    width: 80px !important
}