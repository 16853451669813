.submitpress{
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
  .cardView{
    height: 8rem;
    padding: 1rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    width: auto;
    line-height: 2rem;
    text-align: center;
    background-color:#00B0BA;
    color: white;
    font-weight: bold;
    border-radius: .5rem;
    margin: 2rem;
    &:hover{  
      cursor: pointer;
    }
  }