.header {
  color: var(--darkTealColor);
  margin-bottom: 10px;
  flex-wrap: wrap;
  h4 {
    text-transform: uppercase;
    color: var(--darkTealColor);
  }
}

.notificationContainer {
  background-color: var(--whiteColor);
  border-radius: 20px;
  .firstHeader {
    padding: 16px 20px 10px 20px;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    .filterOption {
      cursor: pointer;
      .notifyTooltip {
        max-width: 250px;
        background-color: var(--headerColor);
        color: var(--darkColor); 
        font-weight: 500;
      }
    }
  }
  .secondaryHeader {
    padding: 5px 20px 0px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--lightGrayColor);
  }
  .filterContainer {
    display: flex;
    gap: 1.5rem;
    .boldText {
      font-weight: 500;
      color: var(--tealColor);
      border-bottom: 1px solid var(--tealColor);
    }
    .filterOption {
      cursor: pointer;
      padding: 0px 10px;
    }
  }
  .notificationListContainer {
    .addBorder {
      border-bottom: 1px solid var(--lightGrayColor);
    }
    .readItem {
      background-color: var(--lightGrayColor);
    }
    .notificationItem {
      padding: 20px 20px 20px 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 10px;
      .itemIcon {
        border-radius: 50px;
        margin-right: 1rem;
      }
      .notificationInfoHeader {
        display: flex;
        gap: 20px;
        align-items: center;
        .dot {
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: var(--darkGrayColor);
        }
        .time {
          color: var(--darkGrayColor);
          padding: 0;
          margin: 0;
        }
        .notificationText {
          padding: 0;
          margin: 0;
          margin-bottom: 0.3rem;
        }
      }
    }
  }
}
.markAll{
  cursor: pointer;
}
