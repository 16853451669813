/* desktop menu css */

.navbar {
  width: 100%;
  background-color: var(--whiteColor);
  border-bottom: 0 !important;
  position: sticky;
  top: 0;
  height: 70px;
  z-index: 9;
}

a.quote-btn {
  background-color: var(--orangeColor);
  color: var(--whiteColor);
  padding: 5px 5px !important;
  text-align: center;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 0.854rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 120px;
}

a.quote-btn span {
  width: 30px;
  border-radius: 50%;
  background-color: var(--whiteColor);
  height: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

a.quote-btn span img {
  object-fit: contain;
  width: 15px;
}

a.quote-btn:hover{
  color: aliceblue;
}

a.quote-btn:hover span i{
  transform: translateX(5px);
  transition: all ease .4s;
}

/* desktop menu css */

@media (min-width: 992px){
  .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
      top: 55px;
      padding: 0;
      margin: 0;
      border-radius: 0;
      border: 1px solid var(--grayColor);
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu li a:hover {
      background-color: var(--whiteColor);
      color: var(--tealColor);
  }

  .navbar {
      border-bottom: 1px solid #00000014;
  }

  a.navbar-brand{
      width: 150px;
      margin-right: 80px;
  }

  ul.left-menubar li a.nav-link {
      font-size: 0.9rem;
      margin: 0 30px 0 0;
      position: relative;
      padding: 0px !important;
  }

  ul.left-menubar li a.nav-link.active, ul.left-menubar li a.nav-link:hover{
      color: var(--tealColor);
  }

  ul.left-menubar li a.nav-link.active::before, ul.left-menubar li a.nav-link:hover::before {
      content: "";
      position: absolute;
      bottom: 0;
      top: 30px;
      background-color: var(--tealColor);
      width: 100%;
      height: 2px;
  }

  ul.right-menubar li{
      display: flex;
      align-items: center;
  }

  ul.right-menubar li .vr{
      background-color: #cbd0e3;
      width: 1px;
      min-height: 20px;
      vertical-align: middle;
      margin: 0 15px;
      opacity: 1;
  }

  ul.right-menubar li a.nav-link h4 {
      color: #36999b;
      font-size: 1.1rem;
  }
}

@media (max-width: 991px){
  a.navbar-brand img{
    width: 150px;
  }
}

.errorBoundary {
  padding: 48px 24px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.errorBoundaryAlert {
  width: 100%;
  max-width: 720px;
}

.errorBoundaryRefreshPage {
  text-decoration: underline;
  cursor: pointer;
}

.dialogDescription {
  margin-top: 20px;
}

.centerPage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.height100 {
  height: 100vh;
}

.cardLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  padding: 4rem 0rem;
}

.createButtonStyle {
  background-color: var(--orangeColor);
  color: var(--whiteColor);
  border: none;
  &:hover {
    background-color: var(--orangeColor);
    color: var(--whiteColor);
  }
  &:disabled {
    background-color: var(--orangeColor);
    color: var(--whiteColor);
  }
  &:active {
    background-color: var(--orangeColor) !important;
    color: var(--whiteColor) !important;
  }
  @media (max-width: 580px){
    font-size: 0.864rem;
  }
}

.createButtonStyle-client {
  background: -webkit-linear-gradient(
    -60deg,
    var(--orangeColor),
    var(--darkTealColor) 40%
  );
  background: linear-gradient(
    -60deg,
    var(--orangeColor),
    var(--darkTealColor) 40%
  );
  border-radius: 20px;
  color: var(--whiteColor);
  &:hover {
    background-color: var(--orangeColor);
    color: var(--whiteColor);
  }
  &:disabled {
    background-color: var(--orangeColor);
    color: var(--whiteColor);
  }
}

.archiveButtonStyle-client {
  background-color: var(--orangeColor);
  border-radius: 20px;

  span {
    color: var(--whiteColor);
  }
  &:hover {
    background-color: var(--archiveColor);
    color: var(--whiteColor);
    border-bottom: 1px solid var(--archiveColor);
  }
  &:disabled {
    background-color: var(--orangeColor);
    color: var(--whiteColor);
  }
}
.br5{
  border-radius: 5px;
}
.br10{
  border-radius: 10px;
}
.br20{
  border-radius: 20px;
}
.flexEnd {
  display: flex;
  align-items: end;
  justify-content: end;
}
.width30p {
  width: 30%;
}
.rowWrap {
  flex-wrap: wrap;
}
.p-r-2 {
  padding-right: 2rem;
}
.inputError {
  color: var(--rejectColor);
  font-size: 14px;
}

.select-group-option {
  label {
    margin-bottom: 0px;
    font-size: 0.864rem;
    color: var(--selectGroupLabelColor);
    // transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
  .select-group-option-add {
    background-color: var(--tealColor);
    border: 0;
    border-bottom: 1px solid var(--grayColor);
    justify-content: center;
    cursor: pointer;
    border-radius: 999em;
    width: 80px;
    text-align: center;
    color: var(--whiteColor);
  }
}

.autoSuggestions{
  position: relative;
  .no-suggestions {
    color: var(--darkGrayColor);
    padding: 0.5rem;
  }
  
  .suggestions {
    border-top-width: 0;
    list-style: none;
    padding-left: 0;
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: var(--tealColor);
    color: var(--whiteColor);
    cursor: pointer;
    font-weight: 700;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid var(--tealColor);
  }

  .suggestionImg {
    transition: transform 0.5s ease;
    margin-bottom: 0.5rem;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 0px;
    bottom: 0;
    padding: 10px 5px;
  }

  .popover{
    max-width: 600px;
    width: auto;
    margin-top: -5px;
  }
  .popover .popover-arrow{
    display: none;
    margin-top: 0px;
    padding-top: 0px;
  }
  .popover .popover-body{
    padding: 10px;
    max-height: 248px;
    overflow-y: auto;
  }
}
.tableCenterPage {
  height: 15vh;
}
.headingPage {
  height: 30vh;
}
.error-background{
  background-color: var(--errorColor);
}
.basic_page_content {
  height: 60vh;
}
.campaignInnerContainer {
  min-height: calc(100vh - 300px);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.eventCampaignInnerContainer{
  min-height: calc(100vh - 200px);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.client_master_card {
  transition: box-shadow 0.3s;
  box-shadow: 3px 3px 10px #0000001a;
  border-radius: 15px;
  width: 100%;
  min-height: 9rem;
  margin: 0.8rem;
  border: 0;
  padding: 15px;
  background-color: var(--whiteColor);
  .top {
    h5 {
      margin-bottom: 0;
      color: var(--blackColor);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .bottom {
    width: 100%;
    h6 {
      margin-bottom: 0;
      margin-top: 30px;
      font-weight: 400;
      color: var(--blackColor);
    }
    .action {
      cursor: pointer;
    }
  }
  &:hover {
    box-shadow: 0px 2px 10px 2px var(--grayColor);
  }
}

.form-check {
  padding-left: 0px;
}

.form-switch .form-check-input {
  width: 50px;
  height: 25px;
  cursor: pointer;
  &:checked {
    background-color: var(--orangeColor);
    border-color: var(--orangeColor);
  }
}

.p-r-35{
  padding-right: 35px !important;
}
.nodata-div {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 120px);
  justify-content: center;
  align-items: center;
}
.customTooltip {
  z-index: 2;
}
.inputSuccess {
  color: var(--approvedColor);
  font-size: 16px;
  word-wrap: break-word;
}

.hp-total-stats {
  .left {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    h1 {
      margin-bottom: 15px;
      @media (max-width: 1199px){
        margin-bottom: 0;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .right {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    @media (max-width: 1199px){
      gap: 15px;
    }
    @media (max-width: 991px){
      grid-template-columns: repeat(2, 1fr);
    }
    .card {
      background-position: top 10px right 30px;
      background-repeat: no-repeat;
      background-size: 20%;
      background: #636b78;
      background: -webkit-linear-gradient(315deg, #414345, #636b78);
      background: linear-gradient(315deg, #414345, #636b78);
      padding: 15px;
      border-radius: 40px;
      border: 0;
      min-height: 260px;
      display: flex;
      justify-content: space-around;
      cursor: pointer;
      transition: all ease 0.3s;
      .top {
        h6 {
          margin-bottom: 0;
          color: var(--lightGrayColor);
        }
      }
      .bottom {
        img {
          width: 50px;
          margin-bottom: 15px;
        }
        h3 {
          font-size: 1.324rem;
          margin-bottom: 0;
          color: var(--whiteColor);
          font-weight: 400;
        }
        h2 {
          margin-bottom: 0;
          font-size: 1.824rem;
          font-weight: 600;
          margin-top: 10px;
          color: var(--whiteColor);
        }
      }
      &:nth-child(2) {
        background: #4d4e8e;
        background: -webkit-linear-gradient(315deg, #8e54e9, #4d4e8e);
        background: linear-gradient(315deg, #8e54e9, #4d4e8e);
      }
      &:nth-child(3) {
        background: #e64c65;
        background: -webkit-linear-gradient(315deg, #f7bb97, #e64c65);
        background: linear-gradient(315deg, #f7bb97, #e64c65);
      }
      &:nth-child(4) {
        background: #ff9700;
        background: -webkit-linear-gradient(315deg, #d7cb65, #ff9700);
        background: linear-gradient(315deg, #d7cb65, #ff9700);
      }
      &:nth-child(5) {
        background: #ff9700;
        background: -webkit-linear-gradient(315deg, #d7cb65, #ff9700);
        background: linear-gradient(315deg, #d7cb65, #ff9700);
      }
      &:nth-child(6) {
        background: #e64c65;
        background: -webkit-linear-gradient(315deg, #f7bb97, #e64c65);
        background: linear-gradient(315deg, #f7bb97, #e64c65);
      }
      &:nth-child(7) {
        background: #4d4e8e;
        background: -webkit-linear-gradient(315deg, #8e54e9, #4d4e8e);
        background: linear-gradient(315deg, #8e54e9, #4d4e8e);
      }
      &:hover {
        box-shadow: 0px 2px 10px 2px #ccc;
      }
    }
  }
}

.description-td {
  width: 50%;
  .display-limit {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.reactTooltip {
  max-width: 250px; 
  background-color: #eaebec !important;
  color: #000 !important;
  font-weight: 500;
  z-index: 9;
  opacity: 1 !important;
}