.radio-area {
  label {
    font-size: 1rem;
    cursor: pointer;
  }
  input[type="radio"] {
    position: relative;
    border: 1px solid var(--blackColor);
    border-radius: 20px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0 0.6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 20px;
    width: 20.5px;
    -webkit-appearance: none;
    opacity: 0.5;
  }

  input[type="radio"]:hover {
    opacity: 1;
  }

  input[type="radio"]:checked {
    background-color: var(--tealColor);
    opacity: 1;
    border: 1px solid var(--tealColor);
    border-radius: 20px;
  }

  input[type="radio"]:before {
    content: "";
    position: absolute;
    right: 50%;
    top: 50%;
    width: 5px;
    height: 10px;
    border: solid var(--whiteColor);
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }

  /* Add this section to style the disabled radio button */
  input[type="radio"]:disabled {
    background-color: gray;
    border: 1px solid gray;
    cursor: not-allowed;
    opacity: 0.5;
  }

  input[type="radio"]:disabled:before {
    content: none; /* Optional: Hide the checkmark if disabled */
  }
}

// input[type="radio"] {
//   display: none;
// }

// input[type="radio"] + label {
//   -webkit-transition: all 500ms ease;
//   transition: all 500ms ease;
//   font-size: 14px;
//   cursor: pointer;
//   border-radius: 50px;
//   background-color: #fff;
//   border: solid 1px var(--gray150);
//   padding: 0.5rem 0.6rem;
//   display: inline-block;
//   -moz-user-select: -moz-none;
//   -webkit-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   width: 100%;
//   margin-bottom: 10px;
//   text-align: center;
// }

// input[type="radio"]:checked + label {
//   -webkit-transition: all 500ms ease;
//   transition: all 500ms ease;
//   background-color: var(--lightOrangeColor);
//   border-color: var(--lightOrangeColor);
// }
