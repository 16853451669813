.inputCheckContainer {
  input[type="radio"] {
    display: none;
  }
  
  input[type="radio"] + label {
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    font-size: 14px;
    cursor: pointer;
    border-radius: 50px;
    background-color: var(--whiteColor);
    border: solid 1px var(--gray150);
    padding: 0.5rem 0.6rem;
    display: inline-block;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
  
  input[type="radio"]:checked + label {
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    background-color: var(--tealColor);
    border-color: var(--tealColor);
    color: var(--whiteColor);
    font-weight: 600;
  }

  input[type="checkbox"] {
    display: none;
  }
  
  input[type="checkbox"] + label {
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    font-size: 14px;
    cursor: pointer;
    border-radius: 50px;
    background-color: var(--whiteColor);
    border: solid 1px var(--gray150);
    padding: 0.5rem 0.6rem;
    display: inline-block;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
  
  input[type="checkbox"]:checked + label {
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    background-color: var(--tealColor);
    border-color: var(--tealColor);
    color: var(--whiteColor);
    font-weight: 600;
  }
  
  
}
